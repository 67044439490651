<div>
      <a routerLink="/reports/" [queryParams]="{program: cfgNew}">
        <img src="../../../assets/add.png" 
        alt="Client config" class="program-icon"  
        style="height:50px; width:50px">
        </a>
  </div>


  <div ngbAccordion #accordion= "ngbAccordion">
    <div ngbAccordionItem="one" [collapsed]="false">
    <h2 ngbAccordionHeader>
    <button ngbAccordionButton>Search Report Cycles</button>
    </h2>
        <div ngbAccordionCollapse>
           <div ngbAccordionBody>
              <div class="row">
                
                <div class="row mb-4">
                   
                       <div class="col-sm-12 col-md-6 col-lg-4">
                          <div class="form-group">
                              <label class="form-control-placeholder" for = "reportcycleid">Report Cycle ID</label>
                              <input class="form-control"  type="text" id="reportcycleid"
                               [(ngModel)]="reportcycleid" 
                               (keypress)="alphanumeric_Only($event)"
                               />
                          </div>
                     </div>

                     <div class="col-sm-12 col-md-6 col-lg-4">

                        <div class="form-group">
                          <label class="form-control-placeholder" for="tinname">Provider TIN *</label>
                      
                          <div ngbDropdown>
                      
                            <button type="button" class="form-control" id="tin" style="text-align:left"  ngbDropdownToggle>
                              {{selectedTIN}}
                            </button>
                      
                            <div ngbDropdownMenu>
                      
                            <div style="width:100%">
                                      <input
                                      class="form-control"
                                      type="text"
                                      name="search"
                                      [(ngModel)] ="tin"
                                      autocomplete="off"
                                      placeholder="Search"
                                      />
                            </div>
                      
                              <button ngbDropdownItem *ngFor="let tin of tinList | filter: tin" 
                            
                                value = {{tin.code}}
                                (click)="SelectTIN(tin.code)">
                                {{ tin.code }}
                              </button>

                              
                              
                            </div>
                            
                        
                            </div>
                          
                          </div>


                      </div>

                      <div class="col-sm-12 col-md-6 col-lg-4">
                        <div class="form-group">

                         <br/>
                          <button class="btn btn-sm btn-outline-primary me-2"
                          style="background-color: #002060; color:#ffffff; height:43px"
                          (click)="accordion.collapse('three')"
                          (click)="SearchReports()"
                          > Search
                        </button>
                        </div>
                   </div>
                     
</div>


<table class=".table table-striped" style="text-align:left">
  <tr>
      <th *ngFor="let header of titlemodel.columns">
          {{ header.label }}
      </th>
  </tr>


<tr *ngFor="let item of ccReportList">
<td>
  {{ item.state }}
</td>
 
<td>
  {{ item.tin }}
</td>
 <td>
  {{ item.provideR_NAME }}
</td>
 <td>
  {{ item.eoC_ID }}
</td>
 <td>
  {{ item.rpT_CYCLE_ID }}
</td>
 <td>
  {{ item.rpT_CONFIG_ID }}
</td>
 <td>
  {{ item.confiG_ID }}
</td>
</tr>
</table>


