<div>
  <a routerLink="/reports/" [queryParams]="{program: cfgNew}">
    <img src="../../../assets/add.png" 
    alt="Client config" class="program-icon"  
    style="height:50px; width:50px">
    </a>
</div>

<div *ngIf="configResult === true">
   
    <table class=".table table-striped">
        <tr>
            <th *ngFor="let header of titlemodel.columns">
                {{ header.label }}
            </th>
        </tr>
    
    
    <tr *ngFor="let item of ccReportList">
      <td>
        {{ item.state }}
      </td>
       
      <td>
        {{ item.tin }}
      </td>
       <td>
        {{ item.provideR_NAME }}
      </td>
       <td>
        {{ item.eoC_ID }}
      </td>
       <td>
        {{ item.rpT_CYCLE_ID }}
      </td>
       <td>
        {{ item.rpT_CONFIG_ID }}
      </td>
       <td>
        {{ item.confiG_ID }}
      </td>
      </tr>
      </table>

      <div class="d-flex justify-content-between p-2">
        <ngb-pagination
          [collectionSize]="collectionSize"
          [(page)]="page"
          [pageSize]="pageSize"
          (pageChange)="refreshReport()"
        >
        </ngb-pagination>
     
      </div>
      
</div>